import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink, formatDate, formatDateAndTime } from "../url";

const ManageCommitee = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [committeeList, setcommitteeList] = useState([]);
    const [formData, setFormData] = useState({
        Name: "",
        Function: "",
        Chair: "",
        EntryID: "",
        InsertedBy: props.loginData[0].Email,
    })

    const getData = async () => {
        try {
            await axios.get(`${serverLink}committee/committee/list`).then((res) => {
                if (res.data.length > 0) {
                    setcommitteeList(res.data)
                }
                setIsLoading(false)
            })

        } catch (e) {

        }
    }

    useEffect(() => {
        getData();
    }, [])

    const addCommitee = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios.post(`${serverLink}committee/committee/add`, formData).then((res) => {
                if (res.data.message === "success") {
                    setLoading(false);
                    getData();
                    Reset();
                    showAlert("success", "committee added successfully", "success");
                }
            })
        } catch (e) {
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }
    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })

    }

    const Reset = () => {
        setFormData({
            ...formData,
            Name: "",
            Function: "",
            Chair: "",
            EntryID: "",
        })
    }
    return isloading === true ? (<Loader />) : (
        <>
            <PageTitle title={["Administartor", "Manage Committee"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Add Committee</h5>
                                <form onSubmit={addCommitee}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Name" className="col-md-12 col-lg-12 col-form-label">Committe Name</label>
                                                    <input type="text" className="form-control" id="Name" value={formData.Name} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Function" className="col-md-12 col-lg-12 col-form-label">Function</label>
                                                    <input type="text" className="form-control" id="Function" value={formData.Function} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Chairman" className="col-md-12 col-lg-12 col-form-label">Chairman</label>
                                                    <input type="email" className="form-control" id="Chair" value={formData.Chair} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <button className="btn btn-sm btn-primary" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." : "Add Commitee"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Event List</h5>
                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Function(s)</th>
                                                <th scope="col">Chairman</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                committeeList.length > 0 ?
                                                    committeeList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    <Link to={`/admin/committee/${x.EntryID}`}>
                                                                        {x.Name}
                                                                    </Link>
                                                                </td>
                                                                <td>{x.Function}</td>
                                                                <td>{x.Chair}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={6}>No Commitees</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageCommitee);