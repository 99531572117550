import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { currencyConverter, formatDateAndTime, serverLink } from "../url";



const Payment = (props) => {
    const [paymentList, setPaymentList] = useState([])
    const [paymentList2, setPaymentList2] = useState([]);
    const [isloading, setIsLoading] = useState(true)

    const getData = async () => {
        try {
            await axios.get(`${serverLink}dashboard/payment-list`).then((res) => {
                if (res.data.length > 0) {
                    setPaymentList(res.data);
                    setPaymentList2(res.data)
                }
            })

            setIsLoading(false)
        } catch (e) {

        }
    }
    useEffect(()=>{
        getData();
    })

    const onSearch = (e) => {
        let filtered = paymentList2.filter(x => 
             x.FirstName !== null && x.FirstName.toLowerCase().includes(e.target.value.toLowerCase())
            || x.MiddleName !== null && x.MiddleName.toLowerCase().includes(e.target.value.toLowerCase())
            || x.LastName !== null && x.LastName.toLowerCase().includes(e.target.value.toLowerCase())
            || x.InsertedDate !== null && x.InsertedDate.includes(e.target.value.toLowerCase())
            || x.Organization !== null && x.Organization.toLowerCase().includes(e.target.value.toLowerCase())
        )
        setPaymentList(filtered)

    }


    return isloading ? (<Loader />) : (
        <>
            <PageTitle title={["Administartor", "Manage payments"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title">Payments</h5>
                                    <input type={"text"} placeholder="search" style={{ height: '30px', borderRadius: '5px' }} onChange={onSearch} />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Full Name</th>
                                                <th scope="col">Organization</th>
                                                <th scope="col">Pariticipation Mode</th>
                                                <th scope="col">Amount Paid</th>
                                                <th scope="col">Payment Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                paymentList.length > 0 ?
                                                    paymentList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    <Link to={`/profile?q=${x.Email + "&" + x.ParticipantID}`}>
                                                                        {x.FirstName + " " + x.MiddleName + " " + x.LastName}
                                                                    </Link>
                                                                </td>
                                                                <td>{x.Organization}</td>
                                                                <td>{x.ModeOfParticipation}</td>
                                                                <td>{x.Amount}</td>
                                                                <td>{formatDateAndTime(x.InsertedDate,"date")}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>No payments</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Payment;