import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { formatDate, formatDateAndTime, serverLink } from "../url";

const ManageSessions = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [sessionList, setsessionList] = useState([]);
    const [speakersList, setspeakersList] = useState([]);
    const [formData, setFormData] = useState({
        Title: "",
        Type: "",
        StartTime: "",
        EndTime: "",
        Date: "",
        Venue: "",
        Chair: "",
        InsertedBy: props.loginData[0].Email,
        EntryID: ""
    })

    const getData = async () => {
        try {
            await axios.get(`${serverLink}session/list/session`).then((res) => {
                if (res.data.length > 0) {
                    setsessionList(res.data)
                }
            })
            await axios.get(`${serverLink}speakers/admin-speakers/list`).then((res) => {
                if (res.data.length > 0) {
                    setspeakersList(res.data);
                }
                setIsLoading(false)
            })


        } catch (e) {

        }
    }

    useEffect(() => {
        getData();
    }, [])

    const addSession = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}session/session/add`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "session added successfully", "success");
                    }
                })
            } else {
                await axios.patch(`${serverLink}session/session/update`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "session updated successfully", "success");
                    }
                })
            }
        } catch (e) {
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }
    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })

    }

    const Reset = () => {
        setFormData({
            ...formData,
            Title: "",
            Type: "",
            StartTime: "",
            EndTime: "",
            Date: "",
            Venue: "",
            Chair: "",
            EntryID: ""
        })
    }
    return isloading ? (<Loader />) : (
        <>
            <PageTitle title={["Administartor", "Manage Event Sessions"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Add Session</h5>
                                <form onSubmit={addSession}>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Title" className="col-md-12 col-lg-12 col-form-label">Title</label>
                                                    <input type="text" className="form-control" id="Title" value={formData.Title} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Chair" className="col-md-12 col-lg-12 col-form-label">Chair</label>
                                                    <input type="text" className="form-control" id="Chair" value={formData.Chair} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Type" className="col-md-4 col-lg-6 col-form-label">Type</label>
                                                    <select className="form-control form-select" onChange={onEdit} id="Type" value={formData.Type}>
                                                        <option value={""}>-select session type-</option>
                                                        <option value={"Lunch"}>Lunch</option>
                                                        <option value={"Facility Tour"}>Facility Tour</option>
                                                        <option value={"Lunch and Facility Tour"}>Lunch and Facility Tour</option>
                                                        <option value={"Job Fair/Internship/Exhibition"}>Job Fair/Internship/Exhibition</option>
                                                        <option value={"Remarks"}>Remarks</option>
                                                        <option value={"Quintuple Helix Engagement"}>Quintuple Helix Engagement</option>
                                                        <option value={"Workshop"}>Workshop</option>
                                                        <option value={"Training"}>Training</option>
                                                        <option value={"Registration"}>Registration</option>
                                                        <option value={"Tea and Networking"}>Tea and Networking</option>
                                                        <option value={"Pitch Competition"}>Pitch Competition</option>

                                                        <option value={"Capacity Development"}>Capacity Development</option>
                                                        <option value={"Plenary"}>Plenary</option>
                                                        <option value={"Job Fair/Exhibition"}>Job Fair/Exhibition</option>
                                                        <option value={"Power Talk"}>Power Talk</option>

                                                        <option value="Fire Side Chat">Fire Side Chat</option>
                                                        <option value="Panel Discussion">Panel Discussion</option>
                                                        <option value="Lecture">Lecture</option>
                                                        <option value="Brainstorming Session">Brainstorming Session</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="StartTime" className="col-md-4 col-lg-6 col-form-label">Start Time</label>
                                                    <input type="time" className="form-control" id="StartTime" value={formData.StartTime} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="EndTime" className="col-md-4 col-lg-6 col-form-label">EndTime</label>
                                                    <input type="time" className="form-control" id="EndTime" value={formData.EndTime} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Venue" className="col-md-12 col-lg-12 col-form-label">Venue</label>
                                                    <textarea type="text" className="form-control" id="Venue" value={formData.Venue} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Date" className="col-md-12 col-lg-12 col-form-label">Date</label>
                                                    <input type="date" className="form-control" id="Date" value={formatDate(formData.Date)} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <button className="btn btn-sm btn-primary" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." : "Add Event"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Session List</h5>
                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Chair</th>
                                                <th scope="col">Start Time</th>
                                                <th scope="col">End Time</th>
                                                <th scope="col">Venue</th>
                                                <th scope="col">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sessionList.length > 0 ?
                                                    sessionList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    <Link to={`/admin/session/${x.EntryID}`}>
                                                                        {x.Title}
                                                                    </Link>
                                                                </td>
                                                                <td>{x.Chair}</td>
                                                                <td>{x.StartTime}</td>
                                                                <td>{x.EndTime}</td>
                                                                <td>{x.Venue}</td>
                                                                <td>{formatDateAndTime(x.Date, "date")}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-sm btn-pimary" onClick={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            Title: x.Title,
                                                                            Type: x.Type,
                                                                            StartTime: x.StartTime,
                                                                            EndTime: x.EndTime,
                                                                            Date: x.Date,
                                                                            Venue: x.Venue,
                                                                            Chair: x.Chair,
                                                                            EntryID: x.EntryID

                                                                        })
                                                                    }}>
                                                                        <i className="bi bi-arrows-angle-expand" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>No session</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageSessions);