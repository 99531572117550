import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

const SideBar = (props) => {

    return (
        <>
            <aside id="sidebar" className="sidebar" >
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link " to="/dashboard">
                            <i className="bi bi-grid" />
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/profile">
                            <i className="bi bi-person" />
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/uploads">
                            <i className="bi bi-arrow-bar-up" />
                            <span>Uploads</span>
                        </Link>
                    </li>
                    <Link className="nav-link collapsed" to="/certificate">
                        <i className="bi bi-download" />
                        <span>Certificate</span>
                    </Link>
                    <Link className="nav-link collapsed" to="/review">
                        <i className="bi bi-book-half" />
                        <span>Review</span>
                    </Link>

                    {
                        props.loginData[0].Role === "Admin" &&
                        <li className="nav-item">
                            <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                                <i className="bi bi-menu-button-wide" /><span>System Management</span><i className="bi bi-chevron-down ms-auto" />
                            </a>
                            <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                                <li>
                                    <Link to="/admin/generate-tag">
                                        <i className="bi bi-circle" /><span>Generate Tag</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/payments">
                                        <i className="bi bi-circle" /><span>Payments</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/papers">
                                        <i className="bi bi-circle" /><span>All Papers</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/events">
                                        <i className="bi bi-circle" /><span>Manage Events</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/session">
                                        <i className="bi bi-square" /><span>Manage Sessions</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/participant">
                                        <i className="bi bi-circle" /><span>Add Participant</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/participants">
                                        <i className="bi bi-circle" /><span>Manage Participants</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/speakers">
                                        <i className="bi bi-circle" /><span>Manage Speakers</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/committee">
                                        <i className="bi bi-square" /><span>Manage Committees</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/partners">
                                        <i className="bi bi-square" /><span>Manage Partners</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/tracks">
                                        <i className="bi bi-square" /><span>Manage Tracks</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/reviewers">
                                        <i className="bi bi-square" /><span>Manage Reviewers</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    }

                </ul>
            </aside>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};



export default connect(mapStateToProps, null)(SideBar);
