

export const reg_trend_chart_data = (data_) => {
  let t_counts = [];
  data_.length > 0 &&
    data_.map((x, y) => {
      t_counts.push(x.RegCount)
    })
  let t_counts_sorted = t_counts.sort(function (a, b) { return a - b; });

  let dates = [];
  data_.length > 0 &&
    data_.map((x, y) => {
      dates.push(x.RegDate.split("T")[0])
    })
  let t_date_sorted = dates.sort(function (a, b) { return b - a; });
  const data = {
    // series: [{
    //   name: "Registered",
    //   data: t_counts_sorted
    // }
    // ],
    // options: {
    //   chart: {
    //     height: 350,
    //     type: 'line',
    //     zoom: {
    //       enabled: false
    //     },
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },
    //   stroke: {
    //     width: [5, 7, 5],
    //     curve: 'straight',
    //     dashArray: [0, 8, 5]
    //   },
    //   title: {
    //     text: '',
    //     align: 'left'
    //   },
    //   legend: {
    //     tooltipHoverFormatter: function (val, opts) {
    //       return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
    //     }
    //   },
    //   markers: {
    //     size: 0,
    //     hover: {
    //       sizeOffset: 6
    //     }
    //   },
    //   xaxis: {
    //     categories: t_date_sorted,
    //   },
    //   tooltip: {
    //     y: [
    //       {
    //         title: {
    //           formatter: function (val) {
    //             return val + " (mins)"
    //           }
    //         }
    //       },
    //       {
    //         title: {
    //           formatter: function (val) {
    //             return val + " per session"
    //           }
    //         }
    //       },
    //       {
    //         title: {
    //           formatter: function (val) {
    //             return val;
    //           }
    //         }
    //       }
    //     ]
    //   },
    //   grid: {
    //     borderColor: '#f1f1f1',
    //   }
    // },


    series: [{
      name: 'Registered',
      data: t_counts_sorted
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
      },
      forecastDataPoints: {
        count: 7
      },
      stroke: {
        width: 5,
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: t_date_sorted,
        tickAmount: 10,
        labels: {
          formatter: function (value, timestamp, opts) {
            return opts.dateFormatter(new Date(timestamp), 'dd MMM')
          }
        }
      },
      title: {
        text: '',
        align: 'left',
        style: {
          fontSize: "16px",
          color: '#666'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: ['#FDD835'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 5, 10, 15, 20, 25, 30]
        },
      },
      yaxis: {
        min: 0,
        max: Math.max(...t_counts_sorted) + 10
      }
    },
  }

  return data;
}


export const partc_mode_chart_data = (data_) => {
  let t_counts = [];
  data_.length > 0 &&
    data_.map((x, y) => {
      t_counts.push(x.PartcCount)
    })
  let t_counts_sorted = t_counts.sort(function (a, b) { return a - b; });

  let labels = [];
  data_.length > 0 &&
    data_.map((x, y) => {
      labels.push(x.PartcMode)
    })
  let t_labels_sorted = labels.sort(function (a, b) { return b - a; });

  const data = {
    series: t_counts_sorted,
    options: {
      chart: {
        width: 380,
        type: 'donut',
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270
        }
      },
      dataLabels: {
        enabled: false
      },
      labels: t_labels_sorted,
      fill: {
        type: 'gradient',
      },
      title: {
        text: ''
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },

  }
  return data;
}



export const partc_type_chart_data = (data_) => {
  let t_counts = [];
  data_.length > 0 &&
    data_.map((x, y) => {
      t_counts.push(x.PartcTypeCount)
    })
  let t_counts_sorted = t_counts.sort(function (a, b) { return a - b; });

  let labels = [];
  data_.length > 0 &&
    data_.map((x, y) => {
      labels.push(x.PartcType)
    })
  let t_labels_sorted = labels.sort(function (a, b) { return b - a; });

  const data = {
    series: t_counts_sorted,
    options: {
      chart: {
        width: 380,
        type: 'donut',
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270
        }
      },
      dataLabels: {
        enabled: false
      },
      labels: t_labels_sorted,
      fill: {
        type: 'gradient',
      },
      title: {
        text: ''
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },

  }
  return data;
}