import React from "react";

const PageTitle = (props) => {

  return (
    <>
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{props.title[0]}</li>
            <li className="breadcrumb-item active">{props.title[1]}</li>
          </ol>
        </nav>
      </div>
    </>
  )
}
export default PageTitle;