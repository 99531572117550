import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert, showConfirm } from "../common/sweetalert/sweetalert";
import { serverLink, formatDate, formatDateAndTime, formatAMPM } from "../url";

const ManageSessionDetails = (props) => {
    const naviagte = useNavigate();
    const location = useLocation();

    const param = location.pathname.split("/")[3];

    const [loading, setLoading] = useState(true);
    const [speakersList, setspeakersList] = useState([]);
    const [session, setSession] = useState([])
    const [assignedSpeakers, setAssignedSpeaker] = useState([]);

    const [formData, setFormData] = useState({
        Title: "",
        StartTime: "",
        EndTime: "",
        SessionID: param,
        SpeakerID: "",
        Date: ""
    })

    const getData = async () => {
        try {
            await axios.get(`${serverLink}session/list/session/${param}`).then((res) => {
                if (res.data.length > 0) {
                    setSession(res.data);
                    setFormData({
                        ...formData,
                        Date: res.data[0].Date
                    })
                }
            })
            await axios.get(`${serverLink}speakers/admin-speakers/list`).then((res) => {
                if (res.data.length > 0) {
                    setspeakersList(res.data);
                }
            })

            await axios.get(`${serverLink}speakers/admin-speakers/speaker-session/list/${param}`).then((res) => {
                if (res.data.length > 0) {
                    setAssignedSpeaker(res.data);
                }
                setLoading(false)
            })
        } catch (e) {

        }
    }

    useEffect(() => {
        getData();
    }, [])


    const addToSpeakerSession = async (e, item) => {
        document.getElementById("btnModal").click();
        // if (e.target.checked === true) {
        //     await axios.post(`${serverLink}speakers/admin-speakers/assign-session/${param}`, item).then((res) => {
        //         if (res.data.message === "success") {
        //             getData();
        //             toast.success("session assigned successfully");
        //         }
        //     })
        // } else {
        //     await axios.delete(`${serverLink}speakers/admin-speakers/remove-session/${param}/${item.EntryID}`).then((res) => {
        //         if (res.data.message === "success") {
        //             getData();
        //             toast.warning("session removed succesfully");
        //         }
        //     })
        // }
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }
    const assignTrack = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${serverLink}speakers/admin-speakers/add-speaker-session`, formData).then((res) => {
                if (res.data.message === "success") {
                    setFormData({
                        ...formData,
                        Title: "",
                        StartTime: "",
                        EndTime: "",
                        SessionID: param,
                        SpeakerID: "",
                        Date: ""
                    })
                    toast.success("added successfully..")

                } else {
                    toast.error("please try again...")
                }
            })
        } catch (e) {
            showAlert("error", "Network error", "error")
        }


    }

    return loading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Administartor", "Session Details"]} />
            {
                session.length > 0 &&
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title justify-content-start">Session Details</h5>
                                    <div className="col-md-12">
                                        <label>Title: &emsp;</label>
                                        <label htmlFor="FirstName" className="fw-bolder">{session[0].Title}</label>
                                        <hr />
                                        <label>Chair: &emsp;</label>
                                        <label htmlFor="FirstName" className="fw-bolder">{session[0].Chair}</label>
                                        <hr />
                                        <label>Date: &emsp;</label>
                                        <label htmlFor="FirstName" className="fw-bolder">{formatDateAndTime(session[0].Date, "date")}</label>
                                        <hr />
                                        <label>Time: &emsp;</label>
                                        <label htmlFor="FirstName" className="fw-bolder">

                                            {formatAMPM(session[0].StartTime)} - {formatAMPM(session[0].EndTime)}</label>
                                        <hr />
                                        <label>Venue: &emsp;</label>
                                        <label htmlFor="FirstName" className="fw-bolder">{session[0].Venue}</label>
                                        <hr />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex justify-content-start">
                                    <h4>Session Speakers</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">FullName</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Position/Organization</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Assign Track</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                speakersList.length > 0 ?
                                                    speakersList.map((x, i) => {
                                                        const check = assignedSpeakers.filter(item => parseInt(item.SpeakerID) === parseInt(x.EntryID));
                                                        const checked = check.length > 0 ? true : false
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{x.FirstName + "" + x.MiddleName + " " + x.LastName}</td>
                                                                <td>{x.Title}</td>
                                                                <td>{x.Designation} at {x.Organization}</td>
                                                                <td>{x.Email}</td>
                                                                <td>
                                                                    {/* <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" id="AssignedCheckBox" defaultChecked={checked} onChange={(e) => addToSpeakerSession(e, x)} />
                                                                    </div> */}
                                                                    <button onClick={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            SpeakerID: x.EntryID
                                                                        })
                                                                    }} type="button" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#basicModal">
                                                                        <i className="bi bi-arrow-up-left-square-fill" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>No speakers</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <button style={{ display: 'none' }} type="button" id="btnModal" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#basicModal">
            </button>

            <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Assign Track to Speaker</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={assignTrack}>
                                <div className="col-md-12">
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <label htmlFor="Title" className="col-md-12 col-lg-12 col-form-label">Title <small>
                                                <strong>
                                                    (Type or select)
                                                </strong>
                                            </small></label>
                                            <input type="text" className="form-control mb-10" id="Title" value={formData.Title} required onChange={onEdit} />
                                            <br />
                                            <select className="form-control form-select" onChange={onEdit} id="Title" required value={formData.Title}>
                                                <option value={""}>-select track title-</option>
                                                <option value={"Lunch"}>Lunch</option>
                                                <option value={"Facility Tour"}>Facility Tour</option>
                                                <option value={"Lunch and Facility Tour"}>Lunch and Facility Tour</option>
                                                <option value={"Job Fair/Internship/Exhibition"}>Job Fair/Internship/Exhibition</option>
                                                <option value={"Remarks"}>Remarks</option>
                                                <option value={"Quintuple Helix Engagement"}>Quintuple Helix Engagement</option>
                                                <option value={"Workshop"}>Workshop</option>
                                                <option value={"Training"}>Training</option>
                                                <option value={"Registration"}>Registration</option>
                                                <option value={"Tea and Networking"}>Tea and Networking</option>
                                                <option value={"Pitch Competition"}>Pitch Competition</option>

                                                <option value={"Capacity Development"}>Capacity Development</option>
                                                <option value={"Plenary"}>Plenary</option>
                                                <option value={"Job Fair/Exhibition"}>Job Fair/Exhibition</option>
                                                <option value={"Power Talk"}>Power Talk</option>

                                                <option value="Fire Side Chat">Fire Side Chat</option>
                                                <option value="Panel Discussion">Panel Discussion</option>
                                                <option value="Lecture">Lecture</option>
                                                <option value="Brainstorming Session">Brainstorming Session</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <label htmlFor="Designation" className="col-md-12 col-lg-12 col-form-label">Date</label>
                                            <input type="date" disabled className="form-control" id="Designation" value={formatDate(formData.Date)} required onChange={onEdit} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="StartTime" className="col-md-12 col-lg-12 col-form-label">Start Time</label>
                                            <input type="time" className="form-control" id="StartTime" value={formData.StartTime} required onChange={onEdit} />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="EndTime" className="col-md-12 col-lg-12 col-form-label">Date</label>
                                            <input type="time" className="form-control" id="EndTime" value={formData.EndTime} required onChange={onEdit} />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary">Proceed to Assign</button>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageSessionDetails);