import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatDateAndTime, serverLink } from "../url";


const Notices = (props) => {
    const [todaysSession, setTodaysSession] = useState([]);
    const [messages, setmessages] = useState([]);
    const color = ['success', 'danger', 'info', 'secondary', 'primary', 'warning']

    const getData = async () => {
        await axios.post(`${serverLink}dashboard/messages`, {Email: props.loginData[0].EmailAddress})
            .then((res) => {
                if (res.data.length > 0) {
                    setmessages(res.data)
                }
            })
            .catch(() => {

            })
    }
    useEffect(() => {
        getData();
    }, [])

    return (
        <section className="section">
            <div className="row">
                <div className="col-lg-10">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Messages <span>| {messages.length}</span></h5>
                            <div className="activity">
                            {
                                        messages.length > 0 ?
                                            messages.map((item, y) => {
                                                var color_ = color[Math.floor(Math.random() * color.length)];
                                                let data = new Date(item.DateSent)
                                                let date = formatDateAndTime(item.DateSent, "date")
                                                let hrs = data.getHours();
                                                let mins = data.getMinutes();
                                                if (hrs <= 9)
                                                    hrs = '0' + hrs
                                                if (mins < 10)
                                                    mins = '0' + mins
                                                const postTime = hrs + ':' + mins
                                                return (
                                                    <div className="activity-item d-flex" key={y} >
                                                        <div className="activite-label" >{postTime}</div>
                                                        <i className={`bi bi-circle-fill activity-badge text-${color_} align-self-start`} />
                                                        <div className="activity-content ms-2">
                                                            <div className="fw-bold text-dark">{item.MessageTitle}<br /></div>
                                                            <p>{item.MessageBody}</p>
                                                            <div className="activite-label">{date}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div>
                                                <label className="alert alert-info w-100">No notice yet</label>
                                            </div>
                                    }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};


export default connect(mapStateToProps, null)(Notices);
