import React from "react";


const Loader = () => {

    return (
       <div className="d-flex justify-content-center" style={{marginTop:'25%'}}>
         <div className="spinner-grow" style={{width: '50px', height: '50px'}} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
       </div>
    )
}

export default Loader;