import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink, formatDate, formatDateAndTime } from "../url";

const ManageEvents = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [formData, setFormData] = useState({
        Title: "",
        Location: "",
        Theme: "",
        Host: "",
        StartDate: "",
        EndDate: "",
        EntryID: "",
        InsertedBy: props.loginData[0].Email,
    })

    const getData = async () => {
        try {
            await axios.get(`${serverLink}events/list/event`).then((res) => {
                if (res.data.length > 0) {
                    setEventList(res.data)
                }
                setIsLoading(false)
            })

        } catch (e) {

        }
    }

    useEffect(() => {
        getData();
    }, [])
    const addEvent = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (formData.EntryID === "") {
                await axios.post(`${serverLink}events/event/add`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        showAlert("success", "event added successfully", "success");
                    }
                })
            } else {
                await axios.patch(`${serverLink}events/event/update`, formData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        showAlert("success", "event updated successfully", "success");
                    }
                })
            }
        } catch (e) {
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }
    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const Reset = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            Title: "",
            Location: "",
            Theme: "",
            Host: "",
            StartDate: "",
            EndDate: "",
            EntryID: "",
        })


    }
    return isloading === true ? (<Loader />) : (
        <>
            <PageTitle title={["Administartor", "Manage Events"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Add Events</h5>
                                <form onSubmit={addEvent}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Title" className="col-md-12 col-lg-12 col-form-label">Event Title</label>
                                                    <input type="text" className="form-control" id="Title" value={formData.Title} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Theme" className="col-md-12 col-lg-12 col-form-label">Theme</label>
                                                    <textarea type="text" className="form-control" id="Theme" value={formData.Theme} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Location" className="col-md-4 col-lg-6 col-form-label">Location</label>
                                                    <input type="text" className="form-control" id="Location" value={formData.Location} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Host" className="col-md-4 col-lg-6 col-form-label">Event Host</label>
                                                    <input type="text" className="form-control" id="Host" value={formData.Host} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="StartDate" className="col-md-12 col-lg-12 col-form-label">Start Date</label>
                                                    <input type="date" value={formatDate(formData.StartDate)} className="form-control" id="StartDate" required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="EndDate" className="col-md-12 col-lg-12 col-form-label">End Date</label>
                                                    <input type="date" value={formatDate(formData.EndDate)} className="form-control" id="EndDate" required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <button className="btn btn-sm btn-primary" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." : formData.EntryID === "" ? "Add Event" : "Update Event"}
                                                    </button>
                                                    {
                                                        formData.EntryID !== "" &&
                                                        <button className="btn btn-sm btn-primary ms-3" onClick={Reset}>Reset</button>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Event List</h5>
                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Event Title</th>
                                                <th scope="col">Host</th>
                                                <th scope="col">Start Date</th>
                                                <th scope="col">End Date</th>
                                                <th scope="col">Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                eventList.length > 0 ?
                                                    eventList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{x.Title}</td>
                                                                <td>{x.Host}</td>
                                                                <td>{formatDateAndTime(x.StartDate, "date")}</td>
                                                                <td>{formatDateAndTime(x.EndDate, "date")}</td>
                                                                <td>{x.Location}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-sm btn-pimary" onClick={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            Title: x.Title,
                                                                            Location: x.Location,
                                                                            Theme: x.Theme,
                                                                            Host: x.Host,
                                                                            StartDate: x.StartDate,
                                                                            EndDate: x.EndDate,
                                                                            EntryID: x.EntryID

                                                                        })
                                                                    }}>
                                                                        <i className="bi bi-arrows-angle-expand" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={6}>No events</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageEvents);