import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { Link } from "react-router-dom";
import axios from "axios";
import { bucketUrl, serverLink } from "../url";
import { showAlert } from "../common/sweetalert/sweetalert";
import { useEffect } from "react";
import { toast } from "react-toastify";

const ManageReveiwers = (props) => {
    const [reviewersList, setreviewersList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isloading, setIsLoading] = useState(true);
    const [filesList, setfilesList] = useState([]);


    const [formData, setFormData] = useState({
        ReveiwerID: "",
        DocumentID: ""
    })
    const [revDocs, setRevDocs] = useState([]);
    const [reviewers, setReviewers] = useState([]);

    const getData = async () => {
        let revs_ = [];
        let revs_docs = [];
        let revs_rows = []
        try {
            await axios.get(`${serverLink}dashboard/document-list`).then((res) => {
                if (res.data.length > 0) {
                    setfilesList(res.data);
                }
            })

            await axios.get(`${serverLink}reviewer/list/all`).then((res) => {
                if (res.data.length > 0) {
                    revs_ = res.data;
                    setReviewers(res.data)
                }
            })

            await axios.get(`${serverLink}reviewer/documents/list/all`).then((res) => {
                if (res.data.length > 0) {
                    revs_docs = res.data;
                    setRevDocs(res.data)
                }
            })

            if (revs_.length > 0) {
                revs_.map((x, i) => {
                    revs_rows.push({
                        ParticipantID: x.ParticipantID,
                        FullName: x.FirstName + " " + x.MiddleName + " " + x.LastName,
                        Email: x.Email,
                        Phone: x.Phone,
                        Title: x.Title,
                        Designation: x.Designation,
                        Organization: x.Organization,
                        Documents: revs_docs.filter(j => j.ReveiwerID.toString() === x.ParticipantID.toString())
                    })
                })
                setreviewersList(revs_rows);
                setIsLoading(false)
            }
            setIsLoading(false)
        } catch (e) {
            showAlert("error", "Network error, please try again", "error");
        }
    }
    useEffect(() => {
        getData();
    }, [])

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const addReviewer = async (e) => {
        e.preventDefault();
        if (formData.DocumentID === "") {
            toast.error("select document");
            return false;
        }

        try {
            await axios.post(`${serverLink}reviewer/assign-document`, formData).then((res) => {
                if (res.data.message === "success") {
                    getData();
                    toast.success("document assigned successfully");
                    document.getElementById("btn_close").click();
                }
            })
        } catch (e) {
            showAlert("error", "Network error, please try again", "error")
        }
    }

    const onSearch = (e) => {

        let data_ = [];
        let filtered = reviewers.filter(x =>
            x.FirstName !== null && x.FirstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.MiddleName !== null && x.MiddleName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.LastName !== null && x.LastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Email !== null && x.Email.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Phone !== null && x.Phone.includes(e.target.value) ||
            x.Designation !== null && x.Designation.toLowerCase().includes(e.target.value) ||
            x.Organization !== null && x.Organization.toLowerCase().includes(e.target.value)
        );
        filtered.map((x, i) => {
            data_.push({
                ParticipantID: x.ParticipantID,
                FullName: x.FirstName + " " + x.MiddleName + " " + x.LastName,
                Email: x.Email,
                Phone: x.Phone,
                Title: x.Title,
                Designation: x.Designation,
                Organization: x.Organization,
                Documents: revDocs.filter(j => j.ReveiwerID.toString() === x.ParticipantID.toString())
            })
        })
        setreviewersList(data_);

    }

    return isloading === true ? (<Loader />) : (
        <>
            <PageTitle title={["Administartor", "Manage Reviewer"]} />

            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title">Reviewer List</h5>
                                    <input type={"text"} placeholder="search" style={{ height: '30px', borderRadius: '5px' }} onChange={onSearch} />
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Full Name</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Designation</th>
                                                <th scope="col">Organization</th>
                                                <th scope="col">Assigned Documents</th>
                                                <th scope="col" >Assign</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                reviewersList.length > 0 ?
                                                    reviewersList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    <Link to={`/admin/participant?q=${x.Email}&${x.ParticipantID}`}>
                                                                        {x.FullName}
                                                                    </Link>
                                                                </td>
                                                                <td>{x.Title}</td>
                                                                <td>{x.Email}</td>
                                                                <td>{x.Designation}</td>
                                                                <td>{x.Organization}</td>
                                                                <td>
                                                                    {
                                                                        x.Documents.length > 0 ?
                                                                            x.Documents.map((z, k) => {
                                                                                return (
                                                                                    <ul key={k}>
                                                                                        <li>
                                                                                            <a target={"_blank"} href={`${bucketUrl}${z.File}`}>{z.FileName}</a>&emsp;
                                                                                            <label className={z.Status === "0" ? "badge bg-danger" : "badge bg-success"}>
                                                                                                {
                                                                                                    z.Status === "0" ? "Not Reviewed" : "Reviewed"
                                                                                                }
                                                                                            </label>
                                                                                        </li>
                                                                                        <hr />
                                                                                    </ul>

                                                                                )
                                                                            })
                                                                            :
                                                                            <div>
                                                                                <label className="badge bg-primary">No Documents Assigned</label>
                                                                            </div>
                                                                    }
                                                                </td>

                                                                <td>
                                                                    <button type="button" id="btnModal" className="btn btn-primary ms-3" data-bs-toggle="modal" data-bs-target="#basicModal" onClick={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            ReveiwerID: x.ParticipantID,
                                                                        })
                                                                    }}>
                                                                        <i className="bi bi-arrow-up-left-square-fill" />
                                                                    </button>
                                                                </td>

                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={6}>No Speakers</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Assign Document</h5>
                            <button type="button" id="btn_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={addReviewer} >
                                <div className="col-md-12">
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <select className="form-control form-select" id="DocumentID" value={formData.DocumentID} required onChange={onEdit}>
                                                <option value={""}>-select document-</option>
                                                {
                                                    filesList.length > 0 &&
                                                    filesList.map((x, i) => {
                                                        return (
                                                            <option value={x.EntryID} key={i}>{x.FirstName} {x.MiddleName} {x.Surname} -- {x.FileName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary">Proceed to Assign</button>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageReveiwers);
