import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink, formatDate, formatDateAndTime, bucketUrl } from "../url";

const ManageCommiteeMembers = (props) => {
    const location = useLocation();
    const param = location.pathname.split("/")[3];

    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [committeeList, setcommitteeList] = useState([]);
    const [committeMembersList, setparticipantsList] = useState([]);

    const [formData, setFormData] = useState({
        FullName: "",
        Name: "",
        Email: "",
        Phone: "",
        Designation: "",
        Photo: "",
        EntryID: "",
        InsertedBy: props.loginData[0].Email,
    })

    const getData = async () => {
        try {
            await axios.get(`${serverLink}committee/committee/list/${param}`).then((res) => {
                if (res.data.length > 0) {
                    setFormData({
                        ...formData,
                        Name: res.data[0].Name
                    })
                    setcommitteeList(res.data)
                }
            })
            await axios.get(`${serverLink}committee/committee/members/list`).then((res) => {
                if (res.data.length > 0) {
                    setcommitteeList(res.data);
                }
                setIsLoading(false)
            })

        } catch (e) {

        }
    }

    useEffect(() => {
        getData();
    }, [])

    const addCommiteeMember = async (e) => {
        e.preventDefault();
        setLoading(true);
        const dt_ = new FormData();
        dt_.append("FullName", formData.FullName);
        dt_.append("Email", formData.Email)
        dt_.append("Phone", formData.Phone)
        dt_.append("Designation", formData.Designation)
        dt_.append("File", formData.Photo)
        dt_.append("CommitteeID", param)

        try {
            await axios.post(`${serverLink}committee/committee/members/add`, dt_).then((res) => {
                if (res.data.message === "success") {
                    setLoading(false);
                    Reset();
                    getData();
                    showAlert("success", "committee added member successfully", "success");
                } else if (res.data.message === "exist") {
                    setLoading(false);
                    Reset();
                    getData();
                    showAlert("warning", "committee member with this email/phone already added", "warning");
                } else {

                }
            })
        } catch (e) {
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }
    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
        if (e.target.id === "Photo") {
            setFormData({
                ...formData,
                Photo: e.target.files[0]
            })
        }

    }

    const Reset = () => {
        setFormData({
            ...formData,
            FullName: "",
            Name: "",
            Email: "",
            Phone: "",
            Designation: "",
            Photo: "",
        })
    }


    return isloading === true ? (<Loader />) : (
        <>
            <PageTitle title={["Administartor", "Manage Committee"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Add Committee Member</h5>
                                <form onSubmit={addCommiteeMember}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Name" className="col-md-12 col-lg-12 col-form-label">Committe Name</label>
                                                    <input type="text" className="form-control" id="Name" value={formData.Name} required onChange={onEdit} disabled />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="FullName" className="col-md-12 col-lg-12 col-form-label">FullName</label>
                                                    <input type="text" className="form-control" id="FullName" value={formData.FullName} required onChange={onEdit} />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Email" className="col-md-12 col-lg-12 col-form-label">Email</label>
                                                    <input type="email" className="form-control" id="Email" value={formData.Email} required onChange={onEdit} />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Phone" className="col-md-12 col-lg-12 col-form-label">Phone</label>
                                                    <input type="text" className="form-control" id="Phone" value={formData.Phone} required onChange={onEdit} />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Designation" className="col-md-12 col-lg-12 col-form-label">Designation</label>
                                                    <input type="text" className="form-control" id="Designation" value={formData.Designation} required onChange={onEdit} />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Photo" className="col-md-12 col-lg-12 col-form-label">Photo</label>
                                                    <input type="file" accept="images/*" className="form-control" id="Photo" required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <button className="btn btn-sm btn-primary" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." : "Add Committee Member"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Commitee Members</h5>
                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Full Name</th>
                                                <th scope="col">Email(s)</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Designation</th>
                                                <th scope="col">Photo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                committeeList.length > 0 ?
                                                    committeeList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    {x.FullName}
                                                                </td>
                                                                <td>{x.Email}</td>
                                                                <td>{x.Phone}</td>
                                                                <td>{x.Designation}</td>
                                                                <td>
                                                                    <img width={'70px'} style={{maxHeight:"70px"}} src={`${bucketUrl}${x.Photo}`}  />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={6}>No Commitees</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageCommiteeMembers);