import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { formatDate, serverLink } from "../url";

const ManageParticipants = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [participantsList, setparticipantsList] = useState([]);
    const [pList2, setpList2] = useState([]);


    const getData = async () => {
        try {
            await axios.get(`${serverLink}participants/admin-participant/list`).then((res) => {
                if (res.data.length > 0) {
                    setparticipantsList(res.data);
                    setpList2(res.data);
                }
                setIsLoading(false)
            })

        } catch (e) {

        }
    }

    const onSearch = (e) => {
        let filtered = pList2.filter(x =>
            x.FirstName !== null && x.FirstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.MiddleName !== null && x.MiddleName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.LastName !== null && x.LastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Email !== null && x.Email.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Phone !== null && x.Phone.includes(e.target.value)
        );
        setparticipantsList(filtered);
    }

    useEffect(() => {
        getData();
    }, [])


    return isloading ? (<Loader />) : (
        <>
            <PageTitle title={["Administartor", "Manage Participants"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title">Participants List</h5>

                                    <input type={"text"} placeholder="search" style={{height:'30px', borderRadius:'5px'}} onChange={onSearch} />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Full Name</th>
                                                <th scope="col">Email Address</th>
                                                <th scope="col">Phone Number</th>
                                                <th scope="col">Gender</th>
                                                <th scope="col">Organization</th>
                                                <th scope="col">Participation Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                participantsList.length > 0 ?
                                                    participantsList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    <Link to={`/admin/participant?q=${x.Email + "&" + x.EntryID}`}>
                                                                        {x.FirstName + " " + x.MiddleName + " " + x.LastName}
                                                                    </Link>
                                                                </td>
                                                                <td>{x.Email}</td>
                                                                <td>{x.Phone}</td>
                                                                <td>{x.Gender}</td>
                                                                <td>{x.Organization}</td>
                                                                <td>{x.ParticipantType}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>No session</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageParticipants);