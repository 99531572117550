import React from "react";
import { Link } from "react-router-dom";

const NotFound404 = (props) => {
    return (
        <>
            <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
                <h1>404</h1>
                <h2>The page you are looking for doesn't exist.</h2>
                <Link className="btn btn-dark" to="/">Back to home</Link>
                <img src="assets/img/not-found.svg" className="img-fluid py-5" alt="Page Not Found" />
            </section>
        </>
    )
}
export default NotFound404;