import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert, showConfirm } from "../common/sweetalert/sweetalert";
import { serverLink, formatDate, formatDateAndTime, encryptData, formatAMPM } from "../url";
import male_avater from '../img/male_avater.jpg'
import JoditEditor from "jodit-react";


const AddParticipant = (props) => {
    const editorRef = React.createRef();
    const [isloading, setIsLoading] = useState(true);

    const naviagte = useNavigate();

    const param = window.location.href.split("=")[1];
    let pEmail = param !== undefined ? param.split("&")[0] : "";
    let pID = param !== undefined ? param.split("&")[1] : "";
    const [loading, setLoading] = useState(false);
    const [sessionList, setsessionList] = useState([]);
    const [participant, setParticipant] = useState([]);
    const [participantSession, setParticipantSession] = useState([]);

    const [formData, setFormData] = useState({
        FirstName: "",
        MiddleName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Organization: "",
        ModeOfParticipation: "",
        Gender: "",
        ParticipantType: "",
        Password: "",
        participationType: "",
        InsertedBy: props.loginData[0].Email,
        EntryID: "",
        ParticipantID: pID,
        Image: "",
        //for speakers
        Title: "",
        Designation: "",
        file: "",
        AboutSpeaker: "",
        //for reveiwers
        Rev_Designation: "",
        Rev_Title: ""
    })
    const getData = async () => {
        if (pID !== "") {
            await axios.post(`${serverLink}participants/participant/fetch`, { Email: pEmail }).then((res) => {
                if (res.data.length > 0) {
                    setFormData({
                        ...formData,
                        FirstName: res.data[0].FirstName,
                        MiddleName: res.data[0].MiddleName,
                        LastName: res.data[0].LastName,
                        Email: res.data[0].Email,
                        Phone: res.data[0].Phone,
                        Organization: res.data[0].Organization,
                        ModeOfParticipation: res.data[0].ModeOfParticipation,
                        Gender: res.data[0].Gender,
                        ParticipantType: res.data[0].ParticipantType,
                        Password: res.data[0].Password,
                        participationType: res.data[0].participationType,
                        InsertedBy: props.loginData[0].Email,
                        EntryID: res.data[0].EntryID,
                    })
                    setParticipant(res.data)
                }
            })

            await axios.get(`${serverLink}participants/admin-participant/session/list/${pID}`).then((res) => {
                if (res.data.length > 0) {
                    setParticipantSession(res.data)
                }
            })
            await axios.get(`${serverLink}session/list/session`).then((res) => {
                if (res.data.length > 0) {
                    setsessionList(res.data)
                }
            })
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getData();
    }, [])

    const addParticipant = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            const sendData = {
                FirstName: formData.FirstName,
                MiddleName: formData.MiddleName,
                LastName: formData.LastName,
                Email: formData.Email,
                Phone: formData.Phone,
                Organization: formData.Organization,
                ModeOfParticipation: formData.FirstName,
                Gender: formData.Gender,
                ParticipantType: formData.ParticipantType,
                Password: formData.Password,
                participationType: formData.participationType,
                InsertedBy: formData.InsertedBy,
                EntryID: formData.EntryID,
                ParticipantID: pID,
                Title: formData.Title,
                Designation: formData.Designation,
                file: formData.file,
                AboutSpeaker: formData.AboutSpeaker,
                Rev_Designation: formData.Rev_Designation,
                Rev_Title: formData.Rev_Title
            }

            if (formData.EntryID === "") {
                await axios.post(`${serverLink}participants/admin-participant/add`, sendData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        Reset();
                        showAlert("success", "participant added successfully", "success");
                    } else if (res.data.message === "exist") {
                        setLoading(false)
                        showAlert("warning", "Participant with this email or phone already registered", "warning")
                    } else {
                        showAlert("error", "Network error, please check your connection", "error")
                    }
                })
            } else {
                await axios.patch(`${serverLink}participants/admin-participant/update`, sendData).then((res) => {
                    if (res.data.message === "success") {
                        setLoading(false);
                        getData();
                        showAlert("success", "participant updated successfully", "success");
                    }
                })
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        })
        if (formData.Password === null || formData.Password === "") {
            setFormData({
                ...formData,
                Password: encryptData(formData.Phone)
            })
        }
    }

    const onAboutSpeakerChange = (e) => {
        setFormData({
            ...formData,
            AboutSpeaker: e
        })
    }

    const addToParticipantSession = async (e, item) => {
        if (e.target.checked === true) {
            await axios.post(`${serverLink}participants/admin-participant/add-session/${pID}`, item).then((res) => {
                if (res.data.message === "success") {
                    getData();
                    toast.success("session added successfully");
                }
            })
        } else {
            await axios.post(`${serverLink}participants/admin-participant/remove-session/${pID}`, item).then((res) => {
                if (res.data.message === "success") {
                    getData();
                    toast.warning("session removed succesfully");
                }
            })
        }
    }
    const EnrolSpeaker = (e) => {
        e.preventDefault();
        showConfirm("warning", "Are you sure you want to enrol this participant as speaker ?", "warning")
            .then(async (isConfirm) => {
                if (isConfirm) {
                    document.getElementById("btnModal").click();
                }
            })
    }
    const uploadPicture = async (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.files[0]
        })
        // let file = e.target.files[0];
        // const dt_ = new FormData();
        // dt_.append("Photo", file);
        // dt_.append("SpeakerID", pID);
        // if (!file.type.includes("image/")) {
        //     toast.error("Only Images are allowed");
        //     return;
        // } else {
        //     await axios.patch(`${serverLink}dashboard/update-passport`, dt_).then((res) => {
        //         if (res.data.message === "success") {
        //         } else {

        //         }
        //     })

        // }
    }

    const Enrol = async (e) => {
        e.preventDefault();
        await axios.post(`${serverLink}speakers/admin-speakers/add`, formData).then((res) => {
            if (res.data.message === "success") {
                if (formData.Image !== "") {
                    const dt = new FormData();
                    dt.append("File", formData.Image);
                    dt.append("Email", formData.Email)
                    axios.post(`${serverLink}speakers/admin-upload-speaker-photo`, dt).then((res) => {
                        if (res.data.message === "success") {

                        }
                    })
                }
                document.getElementById("btn_close").click();
                Reset();
                showAlert("success", "participant enroled as speaker", "success");
            } else if (res.data.message === "exist") {
                toast.error("The speaker have been added already");
            } else {
                toast.error("Network error, please try again");
            }
        })
    }

    const Reset = () => {
        setFormData({
            ...formData,
            FirstName: "",
            MiddleName: "",
            LastName: "",
            Email: "",
            Phone: "",
            Organization: "",
            ModeOfParticipation: "",
            Gender: "",
            ParticipantType: "",
            Password: "",
            participationType: "",
            InsertedBy: props.loginData[0].Email,
            EntryID: "",
            Title: "",
            Designation: "",
            file: "",
            Rev_Designation: "",
            Rev_Title: "",
            Title: "",
            Designation: "",
            Photo: "",
        })
    }

    const EnrolAsReviewer = async (e) => {
        e.preventDefault();
        await axios.post(`${serverLink}reviewer/reviewer/enrol`, formData).then((res) => {
            if (res.data.message === "success") {
                showAlert("success", "participant enroled as reviewer", "success");
                document.getElementById("btn_close_2").click();
            } else if (res.data.message === "exist") {
                toast.error("The reviewer have been added already");
            } else {
                document.getElementById("btn_close_2").click();
                toast.error("Network error, please try again");
            }
        })
    }

    return isloading === true ?
        (<Loader />) : (
            <>
                <PageTitle title={["Administartor", "Add Participants"]} />
                <section className="section">
                    <div className="row align-items-top">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    {
                                        pEmail !== "" &&
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title justify-content-start">Add Participant</h5>
                                            <div>
                                                <button className="btn btn-sm btn-primary" onClick={EnrolSpeaker}  >Enrol as Speaker</button>

                                                <button style={{ display: 'none' }} type="button" id="btnModal" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#basicModal">
                                                </button>
                                                <button type="button" id="btnModal2" className="btn btn-primary ms-3" data-bs-toggle="modal" data-bs-target="#basicModal2">Enrol as Reviewer</button>
                                            </div>
                                        </div>

                                    }
                                </div>
                                <div className="card-body">
                                    <form onSubmit={addParticipant}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="FirstName" className="col-md-12 col-lg-12 col-form-label">First Name</label>
                                                        <input type="text" className="form-control" id="FirstName" value={formData.FirstName} required onChange={onEdit} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="MiddleName" className="col-md-12 col-lg-12 col-form-label">Middle Name</label>
                                                        <input type="text" className="form-control" id="MiddleName" value={formData.MiddleName} onChange={onEdit} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="LastName" className="col-md-12 col-lg-12 col-form-label">Last Name</label>
                                                        <input type="text" className="form-control" id="LastName" value={formData.LastName} required onChange={onEdit} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="Phone" className="col-md-12 col-lg-12 col-form-label">Phone Number</label>
                                                        <input type="text" className="form-control" id="Phone" value={formData.Phone} required onChange={onEdit} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="Email" className="col-md-12 col-lg-12 col-form-label">Email Address</label>
                                                        <input type="email" className="form-control" id="Email" value={formData.Email} required onChange={onEdit} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="Organization" className="col-md-12 col-lg-12 col-form-label">Organization</label>
                                                        <input type="text" className="form-control" id="Organization" value={formData.Organization} required onChange={onEdit} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="Gender" className="col-md-12 col-lg-12 col-form-label">Gender</label>
                                                        <select className="form-control form-select" onChange={onEdit} id="Gender" value={formData.Gender}>
                                                            <option value={""}>-select gender-</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="ModeOfParticipation" className="col-md-12 col-lg-12 col-form-label">Mode Of Participation</label>
                                                        <select className="form-control form-select" onChange={onEdit} id="ModeOfParticipation" value={formData.ModeOfParticipation}>
                                                            <option value={""}>-select Mode Of Participation-</option>
                                                            <option value="In-Person">In-Person</option>
                                                            <option value="O">Online</option>
                                                            <option value="P">P</option>
                                                            <option value="H">H</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="ParticipantType" className="col-md-12 col-lg-12 col-form-label">Participant Type</label>
                                                        <select className="form-control form-select" onChange={onEdit} id="ParticipantType" value={formData.ParticipantType}>
                                                            <option value={""}>-select participant type-</option>
                                                            <option value="speaker">Speaker</option>
                                                            <option value="Student">Student</option>
                                                            <option value="BST">BST</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row mb-3">
                                                    <div className="col-md-8 col-lg-9">
                                                        <label htmlFor="file" className="col-md-4 col-lg-3 col-form-label">Passport</label>
                                                        <input type="file" className="form-control" id="file" required onChange={onEdit} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <label htmlFor="participationType" className="col-md-12 col-lg-12 col-form-label">Event Type</label>
                                                        <select className="form-control form-select" onChange={onEdit} id="participationType" value={formData.participationType}>
                                                            <option value={""} >-select participation type-</option>
                                                            <option value="ITRI">ITRI</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <button className="btn btn-lg btn-primary" type="submit" disabled={loading ? true : false}>
                                                            {
                                                                loading === true &&
                                                                <>
                                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                                </>
                                                            }
                                                            {loading === true ? "Loading..." :
                                                                pID === "" ? "Add Participant" : "Update Participant"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    pEmail !== "" &&

                    <section className="section">
                        <div className="row align-items-top">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-start">
                                            <h4>Participant Sessions</h4>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table table-responsive-lg">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Start Time</th>
                                                        <th scope="col">End Time</th>
                                                        <th scope="col">Chair</th>
                                                        <th scope="col"> Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        sessionList.length > 0 ?
                                                            sessionList.map((x, i) => {
                                                                const check = participantSession.filter(item => item.SessionID === x.EntryID);
                                                                const checked = check.length > 0 ? true : false
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{i + 1}</td>
                                                                        <td style={{ maxWidth: "450px" }}>{x.Title}</td>
                                                                        <td>{formatDateAndTime(x.Date, "date")}</td>
                                                                        <td>{formatAMPM(x.StartTime)}</td>
                                                                        <td>{formatAMPM(x.EndTime)}</td>
                                                                        <td>{x.Chair}</td>
                                                                        <td>
                                                                            <div className="form-check form-switch">
                                                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked={checked} onChange={(e) => addToParticipantSession(e, x)} />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={7}>No session</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }


                <div className="modal fade" id="basicModal" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">Complete Profile</h5>
                                <button type="button" id="btn_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={Enrol}>
                                    <div className="col-md-12">
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <label htmlFor="Title" className="col-md-12 col-lg-12 col-form-label">Title</label>
                                                <input type="text" className="form-control" id="Title" value={formData.Title} required onChange={onEdit} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <label htmlFor="Designation" className="col-md-12 col-lg-12 col-form-label">Designation</label>
                                                <input type="text" className="form-control" id="Designation" value={formData.Designation} required onChange={onEdit} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="Picture" className="col-md-12 col-lg-12 col-form-label">Profile Picture</label>
                                                {/* <img src={formData.Photo === "" || formData.Photo === null ? male_avater : `${serverLink}public/docs/${formData.Photo}`} width="100px" alt="Profile" /> */}
                                                <div className="pt-2">
                                                    <input type={"file"} accept="image/*" id="Image" onChange={uploadPicture} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <label htmlFor="Designation" className="col-md-12 col-lg-12 col-form-label">About Speaker</label>
                                                <JoditEditor
                                                    value={formData.AboutSpeaker}
                                                    ref={editorRef}
                                                    tabIndex={1}
                                                    onChange={onAboutSpeakerChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-primary">Proceed to Enrol</button>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="basicModal2" tabIndex={-1} aria-hidden="true" style={{ display: 'none' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Reviewer</h5>
                                <button type="button" id="btn_close_2" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <form onSubmit={EnrolAsReviewer}>
                                    <div className="col-md-12">
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <label htmlFor="Rev_Title" className="col-md-12 col-lg-12 col-form-label">Title</label>
                                                <input type="text" className="form-control" id="Rev_Title" value={formData.Rev_Title} required onChange={onEdit} />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <label htmlFor="Rev_Designation" className="col-md-12 col-lg-12 col-form-label">Designation</label>
                                                <input type="text" className="form-control" id="Rev_Designation" value={formData.Rev_Designation} required onChange={onEdit} />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-primary">Proceed to Enrol</button>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AddParticipant);
