import React from "react";
import { setDashboardDetails, setLoginDetails } from "../../action/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import ServerLink, {decryptData, encryptData, serverLink} from "../url"
import logo from '../../components/img/logo.png'
import { useEffect } from "react";

const Login = (props) => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        Email: "",
        Password: ""
    })
    useEffect(() => {
        props.setOnLoginDetails([]);
    }, [])

    const Login = (e) => {
        e.preventDefault();
        setLoading(true);
        toast.info("Please wait while login you in...")

        const dt = {
            Email: formData.Email,
            Password: encryptData(formData.Password)
        }

        axios.post(`${serverLink}login/login`, dt).then((res) => {
            if (res.data.message === "success") {
                setTimeout(() => {
                    props.setOnLoginDetails([res.data.userData]);
                    props.setOnDashBoarddata([res.data.userData])
                    setLoading(false);
                }, 1000);
               // props.setOnLoginDetails(res.data)
            } else {
                setLoading(false);
                toast.error('Wrong  credentials, please try again.')
            }
        })
    }
    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    return (
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                        <div className="d-flex justify-content-center py-10">
                            <a href="/" className="logo d-flex align-items-center w-auto">
                                {/*<img   width="100" height="100" src={logo}  alt="logo"/>*/}
                            </a>
                        </div>{/* End Logo */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="pt-4 pb-2">
                                    <h5 className="card-title text-center pb-0 fs-4">
                                        <img width="60" height="60" src={logo}  alt="logo"/>
                                    </h5>
                                    <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                </div>
                                <form className="row g-3 needs-validation" style={{ textAlign: "left" }} onSubmit={Login} >
                                    <div className="col-12">
                                        <label htmlFor="Email" className="form-label">Username</label>
                                        <div className="input-group has-validation">
                                            <input type="email" onChange={onEdit} className="form-control" id="Email" required />
                                            <div className="invalid-feedback">Please enter your email.</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="Password" className="form-label">Password</label>
                                        <input type="password" className="form-control" id="Password" onChange={onEdit} required />
                                        <div className="invalid-feedback">Please enter your password!</div>
                                    </div>
                                    <div style={{textAlign: "right"}}>
                                        <label htmlFor="forgot"><a href="#">Forgot Password?</a></label>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100" type="submit" disabled={loading ? true : false} >
                                            {
                                                loading === true &&
                                                <>
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                </>
                                            }
                                            {loading === true ? "Loading..." : "Login"}
                                        </button>
                                    </div>
                                    {/* <div className="col-12">
                                        <p className="small mb-0">Forgot Password ?
                                            <span href="#">&nbsp;Contact &nbsp;
                                                <strong>
                                                    <a href="mailto:support@conference.com" >support@conference.com</a>
                                                </strong>
                                            </span>
                                        </p>
                                    </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnDashBoarddata:(p)=>{
            dispatch(setDashboardDetails(p))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
